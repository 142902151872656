import React, { useState } from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Navigation from "../components/Navigation";
import ModuleWrapper from "../components/ModuleWrapper";
import Footer from "../components/Footer";

import "../styles/tokens.css";
import "../styles/global.css";
import "../styles/section.css";
import FloatingThingy from "../components/FloatingThingy";
import CopyBlock from "../components/CopyBlock";
import Icon from "../components/Icon";

const PageTemplate = ({ data, location }) => {
  const {
    slug,
    enableDarkNav,
    noIndex,
    pageTitle,
    pageDescription,
    shareImage,
    company,
    navigation,
    floatingCta,
    modules,
  } = data.contentfulPage;

  // const { edges } = data.allContentfulPost;

  let lastBackgroundColor = null;

  const [scroll, setScroll] = useState(false);

  const seo = {
    noIndex,
    pageTitle,
    pageDescription,
    shareImage,
    ogUrl: `${process.env.GATSBY_BASE_URL}${slug === "home" ? "/" : slug}`,
    isBlogPost: false,
  };

  return (
    <Layout
      styles={{ position: "relative" }}
      location={location}
      slug={slug}
      seo={seo}
      scroll={scroll}
      company={company}
    >
      <a href="#main" title="Skip to main content" className="sr-only">
        Skip to main content
      </a>
      <Navigation
        company={company}
        navigation={navigation}
        slug={slug}
        setScroll={setScroll}
        enableDarkNav={enableDarkNav}
      />
      <div id="main">
        {modules &&
          modules.map((mWrapper, index) => {
            if (index + 1 === modules.length) {
              lastBackgroundColor = mWrapper.backgroundColor;
            }
            // console.log("+++++++++++++++++++++");
            // console.log("+++++++++++++++++++++");
            // console.log("mWrapper", mWrapper);
            // console.log("+++++++++++++++++++++");
            // console.log("+++++++++++++++++++++");
            return (
              <ModuleWrapper
                type={mWrapper?.module?.sys?.contentType?.sys?.id}
                props={mWrapper}
                size={mWrapper.size}
                company={company}
                key={mWrapper.id}
              />
            );
          })}
        <Footer
          navigation={navigation}
          company={company}
          backgroundColor={lastBackgroundColor}
        />
      </div>
      {floatingCta && (
        <FloatingThingy>
          <>
            <CopyBlock props={floatingCta} />
            <div style={{ padding: "0 10px", margin: 0, marginTop: "10px" }}>
              <Icon
                props={{
                  name: "fa-phone",
                  type: "solid",
                  size: "lg",
                  color: "black",
                }}
              />
              <a href={`tel:${company.phone}`} title="Call Us">
                {company.phone}
              </a>
            </div>
          </>
        </FloatingThingy>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      name
      slug
      enableDarkNav
      noIndex
      pageTitle
      pageDescription
      shareImage {
        gatsbyImageData(width: 1500)
        description
        url
      }
      company {
        id
        name
        phone
        address {
          childMarkdownRemark {
            html
          }
        }
        email
        copyright
        socialLinks {
          platform
          icon {
            name
            isBrand
            size
          }
          link
        }
        footerNewsletter {
          id
          title
          type
          variant
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            alt
            file {
              gatsbyImageData(width: 1000)
              description
              url
              publicUrl
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          code
          buttonText
          redirectTo {
            slug
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
      }
      floatingCta {
        id
        icon {
          name
          isBrand
          size
          color
          hasBackground
          backgroundColor
        }
        centerAlignIcon
        centerAlignContent
        textColor
        headline
        headlineLevel
        enableHeadlineBottomBorder
        copy {
          raw
        }
        name
        ctaButton {
          id
          title
          to
          opensInNewWindow
          styleAsButton
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
        sys {
          contentType {
            sys {
              id
            }
          }
        }
      }
      navigation {
        id
        name
        darkLogo {
          alt
          file {
            gatsbyImageData(width: 250)
            description
            url
            publicUrl
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
        lightLogo {
          alt
          file {
            gatsbyImageData(width: 250)
            description
            url
            publicUrl
          }
          sys {
            contentType {
              sys {
                id
              }
            }
          }
        }
        pages {
          navLabel
          slug
          pageTitle
          pageDescription
          shareImage {
            gatsbyImageData(width: 1000)
          }
          navSubPages {
            navLabel
            slug
          }
        }
        callToAction {
          navLabel
          slug
        }
      }
      modules {
        id
        name
        size
        isContainerFluid
        backgroundColor
        sectionId
        extraClasses
        headline
        headlineSize
        headlineHorizontalAlign
        sys {
          contentType {
            sys {
              id
            }
          }
        }
        module {
          ... on ContentfulImage {
            id
            alt
            file {
              gatsbyImageData(width: 2000)
              description
              url
              publicUrl
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulMinorCta {
            name
            headline
            backgroundColor
            copy {
              childMarkdownRemark {
                html
              }
            }
            button {
              id
              title
              to
              opensInNewWindow
              styleAsButton
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulVideo {
            name
            videoTitle
            youTubeVideoId
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulBillboard {
            contentHorizontalAlign
            contentVerticalAlign
            contentWidth
            backgroundOverlayColor
            backgroundImage {
              gatsbyImageData(width: 2000)
              description
              url
              publicUrl
              file {
                url
              }
            }
            copy {
              id
              icon {
                name
                isBrand
                size
                color
                hasBackground
                backgroundColor
              }
              centerAlignIcon
              centerAlignContent
              textColor
              headline
              headlineLevel
              enableHeadlineBottomBorder
              copy {
                raw
              }
              name
              ctaButton {
                id
                title
                to
                opensInNewWindow
                styleAsButton
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            logoRail {
              id
              name
              howManyLogosInARow
              logos {
                alt
                file {
                  gatsbyImageData(width: 1000)
                  description
                  url
                  publicUrl
                }
                sys {
                  contentType {
                    sys {
                      id
                    }
                  }
                }
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulPageHeader {
            id
            name
            backgroundImage {
              file {
                gatsbyImageData
                description
                url
                publicUrl
                file {
                  url
                }
              }
            }
            backgroundColor
            headline
            subHeadline
            details {
              childMarkdownRemark {
                html
              }
            }
            callsToActions {
              title
              to
              styleAsButton
            }
            textAlign
            benefit1
            benefit2
            benefit3
            benefit4
            pageHeaderImage {
              file {
                gatsbyImageData
                description
                url
                publicUrl
                file {
                  url
                }
              }
            }
            introCopyBackgroundColor
            introCopyA {
              childMarkdownRemark {
                html
              }
            }
            enableLargeIntroCopyA
            introCopyB {
              childMarkdownRemark {
                html
              }
            }
            enableLargeIntroCopyB
            minimizePadding
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulAccordion {
            id
            name
            items {
              heading
              details {
                id
                copy {
                  raw
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulForm {
            id
            name
            darkTheme
            type
            headline
            headlineLevel
            instructions
            defaultSelectedService
            redirectTo {
              slug
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulHeadlineBanners {
            id
            background
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            name
            banners {
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              name
              headline
              image {
                file {
                  gatsbyImageData(width: 2000)
                  description
                  url
                  publicUrl
                  file {
                    url
                  }
                }
              }
              textAlignment
              destination
            }
          }
          ... on ContentfulHero {
            id
            name
            backgroundImage {
              gatsbyImageData(width: 3000)
              description
              url
              publicUrl
            }
            backgroundOverlay
            contentHorizontalAlign
            headline
            subHeadline
            heroCopy: copy
            cta {
              title
              to
              styleAsButton
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulSplitContent {
            id
            name
            layout
            backgroundColor
            verticalAlign
            contentLeft {
              components {
                ... on ContentfulLeadMagnet {
                  id
                  title
                  type
                  variant
                  description {
                    childMarkdownRemark {
                      html
                    }
                  }
                  image {
                    alt
                    file {
                      gatsbyImageData(width: 1000)
                      description
                      url
                      publicUrl
                    }
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                  }
                  code
                  buttonText
                  redirectTo {
                    slug
                  }
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulVideo {
                  name
                  videoTitle
                  youTubeVideoId
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulImage {
                  id
                  alt
                  file {
                    gatsbyImageData(width: 2000)
                    description
                    url
                    publicUrl
                  }
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulCopyBlock {
                  id
                  icon {
                    name
                    isBrand
                    size
                    color
                    hasBackground
                    backgroundColor
                  }
                  centerAlignIcon
                  centerAlignContent
                  textColor
                  headline
                  headlineLevel
                  enableHeadlineBottomBorder
                  ctaButton {
                    id
                    title
                    to
                    opensInNewWindow
                    styleAsButton
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                  }
                  copy {
                    raw
                  }
                  name
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
              }
            }
            contentRight {
              components {
                ... on ContentfulLeadMagnet {
                  id
                  title
                  type
                  variant
                  description {
                    childMarkdownRemark {
                      html
                    }
                  }
                  image {
                    alt
                    file {
                      gatsbyImageData(width: 1000)
                      description
                      url
                      publicUrl
                    }
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                  }
                  code
                  buttonText
                  redirectTo {
                    slug
                  }
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulVideo {
                  name
                  videoTitle
                  youTubeVideoId
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulImage {
                  id
                  alt
                  file {
                    gatsbyImageData(width: 2000)
                    description
                    url
                    publicUrl
                  }
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulForm {
                  id
                  name
                  type
                  headline
                  headlineLevel
                  instructions
                  redirectTo {
                    slug
                  }
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulTiles {
                  id
                  name
                  headline
                  variant
                  background
                  tiles {
                    title
                    eyebrow
                    body {
                      body
                    }
                    destination
                    iconName
                    iconSource
                    image {
                      alt
                      file {
                        gatsbyImageData(width: 1000)
                        description
                        url
                        publicUrl
                      }
                    }
                  }
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulCopyBlock {
                  id
                  icon {
                    name
                    isBrand
                    size
                    color
                    hasBackground
                    backgroundColor
                  }
                  centerAlignIcon
                  centerAlignContent
                  textColor
                  headline
                  headlineLevel
                  ctaButton {
                    id
                    title
                    to
                    opensInNewWindow
                    styleAsButton
                    sys {
                      contentType {
                        sys {
                          id
                        }
                      }
                    }
                  }
                  enableHeadlineBottomBorder
                  copy {
                    raw
                  }
                  name
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                ... on ContentfulLink {
                  id
                  title
                  to
                  opensInNewWindow
                  styleAsButton
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulCards {
            id
            name
            background
            cardBackground
            variant
            cards {
              headline
              copy
              to
              opensInNewWindow
              backgroundColor
              backgroundImage {
                gatsbyImageData
                description
                url
                publicUrl
                file {
                  url
                }
              }
              icon {
                name
                isBrand
                size
                hasBackground
                backgroundColor
              }

              cta {
                title
                to
                opensInNewWindow
                styleAsButton
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulCta {
            id
            name
            headline
            subHeadline
            action {
              title
              to
              opensInNewWindow
              styleAsButton
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulTiles {
            id
            name
            headline
            variant
            background
            tiles {
              title
              eyebrow
              body {
                body
              }
              destination
              image {
                alt
                file {
                  gatsbyImageData(width: 1000)
                  description
                  url
                  publicUrl
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulTextGrid {
            id
            name
            background
            howManyCopyBlocksInARow
            style
            textCards {
              backgroundColor
              icon {
                name
                isBrand
                size
                hasBackground
                backgroundColor
              }
              copyBlock {
                icon {
                  name
                  isBrand
                  size
                  color
                  hasBackground
                  backgroundColor
                }
                centerAlignIcon
                centerAlignContent
                textColor
                headline
                headlineLevel
                enableHeadlineBottomBorder
                ctaButton {
                  id
                  title
                  to
                  opensInNewWindow
                  styleAsButton
                  sys {
                    contentType {
                      sys {
                        id
                      }
                    }
                  }
                }
                copy {
                  raw
                  references {
                    __typename
                    ... on ContentfulImage {
                      contentful_id
                      __typename
                      id
                      alt
                      file {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
              headline
              copy
              cta {
                title
                to
                opensInNewWindow
                styleAsButton
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulCarousel {
            items {
              textColor
              testimonial {
                testimonial
              }
              authorName
              authorTitle
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            name
            autoPlay
            dynamicHeight
            infiniteLoop
            interval
            showArrows
            showIndicators
            showStatus
            showThumbs
            stopOnHover
            swipeable
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
            transitionTime
            useKeyboardArrows
          }
          ... on ContentfulLeadMagnet {
            id
            title
            type
            variant
            description {
              childMarkdownRemark {
                html
              }
            }
            image {
              alt
              file {
                gatsbyImageData(width: 1000)
                description
                url
                publicUrl
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            code
            buttonText
            redirectTo {
              slug
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulCopyBlock {
            id
            icon {
              name
              isBrand
              size
              color
              hasBackground
              backgroundColor
            }
            centerAlignIcon
            centerAlignContent
            textColor
            headline
            headlineLevel
            enableHeadlineBottomBorder
            ctaButton {
              id
              title
              to
              opensInNewWindow
              styleAsButton
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            copy {
              raw
            }
            name
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulProjectCards {
            id
            name
            cards {
              backgroundImage {
                file {
                  gatsbyImageData(width: 2000)
                  description
                  url
                  publicUrl
                  file {
                    url
                  }
                }
              }
              logo {
                file {
                  gatsbyImageData(width: 500)
                  description
                  url
                  publicUrl
                  file {
                    url
                  }
                }
              }
              headline
              projectDetails {
                childMarkdownRemark {
                  html
                }
              }
              stat1
              stat2
              stat3
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
          ... on ContentfulLogoRail {
            id
            name
            howManyLogosInARow
            logos {
              alt
              file {
                gatsbyImageData(width: 1000)
                description
                url
                publicUrl
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            sys {
              contentType {
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default PageTemplate;
